import { FC, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAuth } from 'src/contexts/AuthContext';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { AlertStandard } from '../../AlertStandard';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { Member } from '../../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { AccordionInfo } from '../../AccordionInfo';
import {
  ACCORDION_TITLE_LIVESTREAM,
  AccordionBodyLivestream,
} from '../../livestream/AccordionBodyLivestream';

const CheckedInDialogUnmemoized: FC = () => {
  const { foundTeam, title: tournamentTitle } = useTournamentRegistration();

  const { members } = foundTeam!;
  const [isTeamCheckedIn, setIsTeamCheckedIn] = useState(
    members.some((member) => {
      const { checkedIn } = member;
      return !checkedIn;
    }),
  );
  const { checkIn } = useRegistrationFunctions();
  const { go } = useWizard();
  const { uid } = useAuth();
  const { isCaptain, teammates } = useMemo(() => {
    return members.reduce(
      (acc, member) => {
        const { userId, status } = member;
        if (userId === uid) {
          return { ...acc, isCaptain: status === 'captain' };
        }
        return { ...acc, teammates: [...acc.teammates, member] };
      },
      { isCaptain: false, teammates: [] as Member[] },
    );
  }, [members, uid]);

  const checkInEveryone = useCallback(async () => {
    try {
      //if the user has not updated their username, require them to do so
      // if (!userData?.username || userData?.username?.length > 25) {
      //   go('CreateUsernameDialog');
      //   return;
      // }
      await checkIn(true);
      setIsTeamCheckedIn(true);
    } catch (e) {
      console.error(e);
    } finally {
      go(undefined);
    }
  }, [checkIn, go]);

  const membersToCheckIn = teammates.filter((member) => {
    return member.status === 'accepted' && !member.checkedIn;
  });
  const showTeamDetails = isCaptain && membersToCheckIn.length > 0;

  return (
    <DialogBodyStandard
      title={`${isTeamCheckedIn ? 'Team' : ' '} Check-in Successful!`}
      description={
        <Stack direction="column" spacing={2} pb={showTeamDetails ? 0 : 4}>
          <Typography variant="h6">{`You've successfully checked in${
            isTeamCheckedIn ? ' everyone' : ''
          }!`}</Typography>
          {showTeamDetails && (
            <>
              <Typography variant="h6">
                Would you like to check in everyone on your team?
              </Typography>
              <AlertStandard
                message={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      my: -1,
                      fontWeight: 500,
                    }}
                    color={'warning.light'}
                  >
                    If any member on your team does not show up in time for your
                    first match, your entire team will be
                    <span style={{ fontWeight: 700 }}> disqualified</span>.
                  </Typography>
                }
                severity="warning"
              />
            </>
          )}
          <AccordionInfo
            title={ACCORDION_TITLE_LIVESTREAM}
            body={<AccordionBodyLivestream tournamentTitle={tournamentTitle} />}
            openInDialog={false}
          />
        </Stack>
      }
    >
      {showTeamDetails && (
        <DialogActionsStandard
          buttons={[
            {
              children: 'Check-In Everyone',
              type: 'submit',
              isAsync: true,
              onClick: checkInEveryone,
              color: 'secondary',
            },
            {
              children: 'Close',
              isAsync: false,
              onClick: () => {
                return go(undefined);
              },
            },
          ]}
        />
      )}
    </DialogBodyStandard>
  );
};

export const CheckedInDialog = memo(CheckedInDialogUnmemoized);
