import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { LogoSlogan } from '../header/LogoSlogan';
import { Link } from '../Link';
import { useCallback } from 'react';

export function Footer() {
  const theme = useTheme();

  const linkTypographySx = {
    color: theme.palette.text.secondary,
    textDecorationColor: theme.palette.text.secondary,
  };

  const currentYear = new Date().getFullYear();

  const displayUspUi = useCallback(() => {
    if ('__uspapi' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__uspapi('displayUspUi');
    }
  }, []);

  return (
    <footer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          background: 'rgba(0, 14, 33, 0.8)',
          width: '100%',
          borderTop: `1px solid ${theme.palette.primary.dark}`,
          padding: { xs: 3, lg: '10px 12px' },
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <LogoSlogan />
          <Stack
            sx={{
              flexDirection: { xs: 'column', lg: 'row-reverse' },
              alignItems: { xs: 'flex-end', lg: 'center' },
            }}
          >
            <Stack direction="row">
              <Link
                href="/docs/tos.html"
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                }}
              >
                <span style={{ textDecoration: 'underline' }}>Copyright</span> ©
                {currentYear} BluMint Inc. All rights reserved.
              </Link>
            </Stack>
            <Stack direction="row" spacing={2} mx={{ xs: 0, lg: 4 }}>
              <Link href="/docs/tos.html" style={linkTypographySx}>
                Terms Of Use
              </Link>
              <Link href="/docs/privacy.html" style={linkTypographySx}>
                Privacy Policy
              </Link>
              <Tooltip title="We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here.">
                <Link
                  href="#"
                  onClick={displayUspUi}
                  style={{ ...linkTypographySx, textDecoration: 'underline' }}
                >
                  Do Not Sell My Info
                </Link>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </footer>
  );
}
