import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import {
  GroupFilter,
  ChannelGroup,
  GroupFilterMap,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { useTheme } from '@mui/material/styles';
import { AvatarGroupNext } from '../../AvatarGroupNext';
import { PulsateUnreadMatch } from '../../PulsateUnreadMatch';
import { Pulsate } from '../../animations/Pulsate';
import { ImageOptimized } from '../../image/ImageOptimized';
import { Attendable } from '../Attendable';
import { useParseChannelPreview } from '../../../hooks/messaging/useParseChannelPreview';
import { useRemoveChannelGroupDialog } from '../../../hooks/messaging/useRemoveChannelGroupDialog';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { TextOverlayAvatar } from '../TextOverlayAvatar';
import {
  TOURNAMENT_PHASE_EDITABLE,
  TOURNAMENT_PHASE_PAST,
  TOURNAMENT_PHASE_PRESENT,
  TournamentPhaseEditable,
  TournamentPhasePresent,
  TournamentPhasePast,
} from '../../../../functions/src/types/firestore/Game/Tournament';
import { useDateFormatter } from '../../../hooks/useDateFormatter';
import { NUMERIC_NOZONE_NOCOMMA } from '../../../util/dates/presets/dateTimes';

export const ANCHOR_ORIGIN_TOP_RIGHT = {
  horizontal: 'right',
  vertical: 'top',
} as const;

export const ANCHOR_ORIGIN_BOTTOM_RIGHT = {
  horizontal: 'right',
  vertical: 'bottom',
} as const;

export type ChannelGroupSidebarProps = {
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>;
  isLastTournamentChannelGroup: boolean;
};

const ChannelGroupSidebarUnmemoized = ({
  channelGroup,
  isLastTournamentChannelGroup,
}: ChannelGroupSidebarProps) => {
  const theme = useTheme();
  const { parseChannelPreview } = useParseChannelPreview();
  const { open: openRemoveChannelGroupDialog } = useRemoveChannelGroupDialog();
  const {
    openChannelGroup,
    closeChannelGroup,
    closeChannel,
    channelGroupId: channelGroupActiveId,
  } = useActiveChannelGroup();
  const format = useDateFormatter(NUMERIC_NOZONE_NOCOMMA);

  const {
    channelGroupUnreadCount,
    permanence,
    isTournament,
    isSelected,
    isRemovable,
    isSupport,
    isDm,
    groupFilter,
    imgUrls,
    onlineUserId,
    title,
    tournamentId,
    channelGroupId,
    phase,
    date,
  } = parseChannelPreview(channelGroup);

  const isTemporary = permanence === 'temporary';
  const isUnpinnable = permanence === 'unpinnable';
  const dateFormatted = date ? format(date) : undefined;

  const avatarBorderColor = isSupport
    ? theme.palette.primary.main
    : (phase &&
        (TOURNAMENT_PHASE_PRESENT.includes(phase as TournamentPhasePresent)
          ? theme.palette.warning.main
          : TOURNAMENT_PHASE_PAST.includes(phase as TournamentPhasePast)
          ? theme.palette.text.disabled
          : TOURNAMENT_PHASE_EDITABLE.includes(phase as TournamentPhaseEditable)
          ? theme.palette.secondary.main
          : undefined)) ??
      undefined;

  const showDivider = isLastTournamentChannelGroup && isTournament;
  const isAvatarGroup = imgUrls.length > 2;

  const toggleSelected = useCallback(() => {
    if (channelGroupActiveId === channelGroupId) {
      closeChannelGroup();
    } else {
      closeChannel();
      openChannelGroup(channelGroupId);
    }
  }, [
    channelGroupActiveId,
    channelGroupId,
    closeChannel,
    closeChannelGroup,
    openChannelGroup,
  ]);

  const togglePermanence = useCallback(async () => {
    const { setTournamentChannelGroupExternal } = await import(
      '../../../firebaseCloud/messaging/setTournamentChannelGroupExternal'
    );
    await setTournamentChannelGroupExternal({
      tournamentId: (groupFilter as GroupFilter<'Tournament'>)[0].tournamentId,
      permanence: isTemporary ? 'unpinnable' : 'temporary',
    });
  }, [groupFilter, isTemporary]);

  const removeChannelGroup = useCallback(() => {
    return openRemoveChannelGroupDialog({
      groupFilter,
      isSupport,
    });
  }, [groupFilter, isSupport, openRemoveChannelGroupDialog]);

  const badgeContentUnread = useMemo(() => {
    return channelGroupUnreadCount > 0 && channelGroupUnreadCount < 10
      ? channelGroupUnreadCount
      : channelGroupUnreadCount >= 10
      ? '9+'
      : undefined;
  }, [channelGroupUnreadCount]);

  const badgeContentActions = useMemo(() => {
    return isTemporary ? (
      <PushPinIcon
        sx={{
          width: '14px',
          height: '14px',
          color: theme.palette.primary.dark,
          transform: 'rotate(45deg)',
        }}
      />
    ) : isUnpinnable ? (
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <ImageOptimized
          src="/assets/icons/unpin-primary-dark.svg"
          layout={'fill'}
        />
      </Box>
    ) : isRemovable ? (
      <CloseIcon
        sx={{
          width: '16px',
          height: '16px',
          color: theme.palette.primary.dark,
        }}
      />
    ) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemovable, isTemporary, isUnpinnable]);

  return (
    <Stack alignItems="center">
      <Tooltip arrow title={title} placement="left">
        <Stack
          width="76px"
          alignItems="center"
          p={2}
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
            background: isSelected
              ? theme.palette.background.elevation[12]
              : undefined,
          }}
        >
          <PulsateUnreadMatch
            tournamentId={tournamentId}
            sx={{ borderRadius: '50%' }}
          >
            <Pulsate
              pulsate={isTemporary}
              opacityRange={[0, 0.5]}
              sx={{ borderRadius: '50%' }}
            >
              <Attendable
                badgeContent={badgeContentUnread}
                color={'error'}
                anchorOrigin={ANCHOR_ORIGIN_TOP_RIGHT}
              >
                <Attendable
                  badgeContent={badgeContentActions}
                  color={'primary'}
                  anchorOrigin={ANCHOR_ORIGIN_BOTTOM_RIGHT}
                  onClick={
                    isTournament
                      ? togglePermanence
                      : isRemovable
                      ? removeChannelGroup
                      : undefined
                  }
                  sx={{
                    '.MuiBadge-badge': {
                      height: '17px',
                      minWidth: '17px',
                      width: '100%',
                      right: '8px',
                    },
                  }}
                >
                  <Box onClick={toggleSelected} position="relative">
                    <AvatarGroupNext
                      imgUrls={imgUrls}
                      showStatus={isDm}
                      max={2}
                      total={imgUrls.length}
                      borderColor={avatarBorderColor}
                      userId={onlineUserId}
                      sx={{
                        '.MuiAvatar-root': {
                          backgroundColor: theme.palette.action.disabledOpaque,
                          fontSize: '16px',
                          border: `2px solid ${
                            isSelected
                              ? theme.palette.background.elevation[12]
                              : theme.palette.background.elevation[4]
                          } !important`,
                        },
                        '.MuiAvatar-root:first-of-type': {
                          ml: '-32px',
                        },
                        '.MuiAvatar-root:last-of-type': {
                          mt: '14px',
                        },
                        '.MuiAvatar-root:nth-of-type(1)': {
                          mt: isAvatarGroup ? '-10px' : '0px',
                          mr: isAvatarGroup ? '-8px' : '0px',
                        },
                        pt: isAvatarGroup ? 2 : 0,
                      }}
                      badgeSx={{
                        '& .MuiBadge-badge': {
                          borderRadius: '50%',
                          height: '16px',
                          width: '16px',
                          border: `2px solid ${theme.palette.background.elevation[4]}`,
                          boxShadow: `inset 0 0 0 1px ${theme.palette.text.primary}`,
                        },
                      }}
                    />
                    {!!dateFormatted && (
                      <TextOverlayAvatar
                        text={dateFormatted}
                        sx={{
                          height: '54px',
                          width: '54px',
                          wordSpacing: '54px',
                        }}
                      />
                    )}
                  </Box>
                </Attendable>
              </Attendable>
            </Pulsate>
          </PulsateUnreadMatch>
        </Stack>
      </Tooltip>

      {showDivider && (
        <Divider
          sx={{
            color: theme.palette.primary.dark,
            width: '64px',
          }}
        />
      )}
    </Stack>
  );
};
export const ChannelGroupSidebar = memo(ChannelGroupSidebarUnmemoized);
